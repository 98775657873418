@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}

@keyframes pulse {
  0% { transform: scale(0.8); opacity: 0.7; }
  50% { transform: scale(1); opacity: 0.3; }
  100% { transform: scale(0.8); opacity: 0.7; }
}

@layer utilities {
  .shimmer {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.animation-delay-500 {
  animation-delay: 0.5s;
}
